.form-label {
    font-weight: 500;
    font-size: 14px !important;
    margin-bottom: 0px !important;
}

.inline-form-label {
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-data-label {
    display: block;
    width: 100%;
    /*height: calc(1.47em + 1rem + 2px);*/
/*    padding: .5rem .875rem;*/
/*    font-size: .8125rem;*/
    font-weight: 400;
    line-height: 1;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: var(--dark-blue) !important;
}

.help-block, .invalid-feedback, .valid-feedback {
    font-size: .6875rem;
    margin-top: .325rem;
}

.help-block {
    color: #909090;
}

.inline .form-label {
    width: 50% !important;
}

.inline .form-control-custom, .inline .react-datepicker-wrapper, .inline .react-datepicker__input-container {
    width: 50% !important;
    display: unset !important;
}

.inline .custom-checkbox {
    width: 50%;
    display: inherit;
}

.inline .form-control-custom.no-border {
    padding: 0;
}

.form-control-custom, .form-control {
    display: block;
    width: 100%;
    /*height: calc(1.47em + 1rem + 2px);*/
    padding: .5rem .875rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.47;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid silver;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

    .form-control-custom:focus, .form-control:focus {
        color: #000;
        background-color: #fff;
        border-color: #255398;
        outline: 0;
        box-shadow: 0 0 0 0.2rem transparent;
    }

.search-inline-button {
    top: 10px;
    right: 15px;
}

.inline-button, .search-inline-button {
    position: absolute;
    transition: right .2s;
}


.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(111, 178, 70, .5);
    border-color: rgba(111, 178, 70, .5);
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #255398;
    background-color: #255398;
}

.react-datepicker-wrapper {
    display: block !important;
}

.form-group {
    margin-bottom: .5rem;
}

.no-border {
    border: 0 !important;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.19437rem;
    padding-left: 1.625rem;
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    z-index: 1;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #255398 !important;
    border-color: #255398 !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #255398 !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(37,83,152, .25) !important;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    background-color: lightgrey;
    color: grey !important;
}

.custom-file-label {
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
}

.input-icons button {
    position: absolute;
    right: 15px;
    bottom: 10px;
    z-index: 3;
    display: block;
    height: calc(1.47em + 1rem);
    padding: .5rem .875rem;
    line-height: 1.47;
    color: #495057;
    background-color: #f3f3f3;
    border-left: inherit;
    border-radius: 0 4px 4px 0;
    border: 1px solid #ced4da;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
}

.icon {
    padding: 10px;
    min-width: 40px;
}

.pr-4rem {
    padding-right: 4rem;
}

.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}
.custom-control-label::before, .custom-file-label, .custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e);
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.inline-text-box {
    display: inline-block;
    padding: .5rem .875rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.47;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid silver;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

    .inline-text-box:focus {
        color: #000;
        background-color: #fff;
        border-color: #255398;
        outline: 0;
        box-shadow: 0 0 0 0.2rem transparent;
    }

.align-items-center {
    display: flex;
    align-items: center; /*Aligns vertically center */
    /*justify-content: center;*/ /*Aligns horizontally center */
}

.w-unset {
    width: unset !important;
}