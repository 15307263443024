:root {
    --d2p-blue: #255398;
}

.ant-tabs-tab {
    font-size: 16px;
}

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        /*text-decoration: underline 2px solid;*/
        text-decoration-color: #255398 !important;
        color: #ffffff;
        background: #255398;
    }

    .ant-tabs-tab:hover {
        color: #255398;
    }

.tiny-comments .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-decoration: unset;
}

.card-container {
    padding: 24px;
    overflow: hidden;
    background: #f5f5f5;
}

.ant-tabs-content {
    background: #fff;
    border-color: #fff;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #fff;
    background: #255398;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 2px 2px 0 0;
}

.card-container > .ant-tabs-card .ant-tabs-tab-active, [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
    background: #255398;
}

.card-container p {
    margin: 0;
}

.card-container > .ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
}

    .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
        padding: 16px;
        background: #fff;
    }

.card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
}

.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}

.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
}

#components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
    background: #f5f5f5;
}

.ant-layout {
    background: #fff;
}

.ant-tabs-left {
    height: 100%;
}
.ant-tabs-left .ant-tabs-nav {
    background: #f5f5f5;
    width: 250px;
}

.ant-tabs-left .ant-tabs-nav .ant-tabs-tab-active {
    background: #fff !important;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar {
    left: 0;
}

.ant-tabs-ink-bar {
    background: #255398;
}

.ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
    color: #255398;
}

.ant-select.form-control-custom {
    padding: 0;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #e6f7ff;
}

.ant-drawer-header {
    position: relative;
    padding: 16px 42px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: 2px solid #255398;
    border-radius: 2px 2px 0 0;
    background: #ededed;
}

.ant-drawer-close {
    left: 0;
    padding: 16px;
    font-size: 20px;
}

.ant-drawer-title {
    font-size: 20px;
}

.ant-drawer-footer {
    padding: 25px;
}

.ant-drawer-footer button, .ant-modal-footer button {
    margin: 0 10px;
}

.ant-modal-footer {
    text-align: left;
}

/*TABLE*/
.ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: #f5f5f5;
    border-bottom: 1px solid #255398;
    transition: background 0.3s ease;
}

.ant-pagination-item-active:focus-visible a, .ant-pagination-item-active:hover a {
    color: #255398;
}

.ant-pagination-item-active:focus-visible, .ant-pagination-item-active:hover {
    border-color: #255398;
}

.ant-pagination-item-active {
    font-weight: 500;
    background: #255398;
    border-color: #255398;
    color: #fff;
}

/*.ant-input {
    display: block;
    width: 100%;
    height: calc(1.47em + 1rem + 2px);
    padding: .5rem .875rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.47;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid silver;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

    .ant-input:focus, .ant-input-focused {
        color: #000;
        background-color: #fff;
        border-color: #255398;
        outline: 0;
        box-shadow: 0 0 0 0.2rem transparent;
    }

    .ant-input:hover {
        border-color: #255398;
        border-right-width: 1px !important;
    }

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    height: calc(1.47em + 1rem + 2px);
    font-size: .8125rem;
    color: #000;
    border: 1px solid silver;
}*/

.ant-spin-container {
    overflow-x: hidden;
    overflow-y: auto;
}
.smart-form .ant-spin-container {
    height: 50vh;
    overflow-y: auto;
}


.ant-list-item-meta {
    /*margin: 12px 0;*/
    padding-left: 6px;
}

.ant-list-item {
    padding: unset;
    padding-right: 6px;
    border-bottom: none !important;
    padding: 0px 0px !important;
}

.ant-card-grid {
    padding: unset !important;
}

.ant-card-grid div {
    padding: 14px;
    margin: 10px;
}

    .ant-card-grid div.border-online {
        border-left: 7px solid green;
    }

    .ant-card-grid div.border-offline {
        border-left: 7px solid red;
    }

    .ant-card-grid div.border-yell {
        border-left: 7px solid #fac905;
    }
.ant-card-grid-hoverable:hover {
    cursor: pointer;
}

.ant-list-bordered {
    width: 75%;
    margin: 0 auto;
}

.antd-message-loading
.ant-list-header {
    background-color: #ededed;
    margin: 0;
    padding: 10px !important;
    color: #000;
    font-weight: 600;
    margin-bottom: 10px !important;
    border-bottom: 2px solid #255398 !important;
}

.ant-message {
    top: 60px !important;
}

.ant-message-notice-content {
    padding: 0 !important;
    font-size: 16px;
}

.ant-message-custom-content span {
    top: 3px;
    position: relative;
}

.ant-message-success {
    padding: 0 10px 0 0 !important;
    background: #d4edda !important;
}

.ant-message .anticon {
    border-radius: 3px;
}

.ant-message-notice-content span.anticon.anticon-check-circle {
    padding: 10px;
    color: #fff !important;
    background: #255398;
    top: 0 !important;
}

/*LIST SORT*/
.list-sort-demo-wrapper {
    position: relative;
    background: #f5f5f5;
    overflow: hidden;
}

.list-sort-demo {
    margin: 40px auto;
    max-width: 350px;
    width: 90%;
    cursor: move !important;
    position: relative;
}

    .list-sort-demo > div {
        overflow: hidden;
    }

.list-sort-demo-list {
    background: #fff;
    border-radius: 6px;
    margin: 5px auto;
    padding: 10px;
    transition: box-shadow .5s, transform .5s;
}

    .list-sort-demo-list.list-drag-selected {
        box-shadow: 0 8px 20px #E6E6E6;
        transform: scale(1.1) !important;
    }

.list-sort-demo-text {
    width: 100%;
    display: inline-block;
}

    .list-sort-demo-text h1 {
        font-size: 18px;
    }

    .list-sort-demo-text p {
        font-size: 12px;
    }

.ant-list-split .ant-list-item {
    border-bottom: 1px solid #255398;
}

    /**Transfer stuff*/
.anticon.anticon-right, .anticon.anticon-left {
    vertical-align: 0 !important;
}

.ant-transfer-list-content-item-checked, .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
    background-color: #255398;
    color: white;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #255398;
    border-color: white;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: white;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: white;
}

.ant-btn-primary {
    background: #255398;
    border-color: white;
}

    .ant-btn-primary:hover, .ant-btn-primary:focus {
        background: #255398;
        border-color: white;
    }

    .ant-btn-primary:active {
        background: #588e38;
        border-color: #588e38;
    }

    @media screen and (max-width: 320px) {
        .list-sort-demo-text h1 {
        font-size: 14px;
        line-height: 28px;
    }
}

.ant-descriptions {
    padding: 10px 10px 0;
}

.ant-descriptions-item-label {
    font-weight: 600;
}

.tiny-comments.ant-layout-sider {
    flex: 0 0 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
    width: 300px !important;
}

.tiny-comments.ant-layout-sider.ant-layout-sider-collapsed {
    width: 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
}

.ant-layout-sider-collapsed .ant-layout-sider-children {
    display: none;
}

.tiny-comments .ant-spin-container {
    height: unset !important;
}

.ant-picker-range {
    display: inline-flex !important;
}

.ant-picker-focused {
    border-color: var(--d2p-blue);
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px hsla(97, 44%, 49%, .2);
}

.ant-picker:hover, .ant-picker-focused {
    border-color: var(--d2p-blue) !important;
    border-right-width: 1px !important;
}

.ant-picker-range .ant-picker-active-bar {
    background: var(--d2p-blue) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid var(--d2p-blue) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: hsla(97, 44%, 49%, .2);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: var(--d2p-blue);
}

/*.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
    border-color: var(--d2p-blue);
}*/

.ant-descriptions-title span {
    color: #255398;
}

.ant-list-item-flex {
    display: flex;
}

.ant-list-item-flex .ant-list-item-action {
    margin-top: 0;
}

    .ant-list-item-flex a.ant-typography, .ant-list-item-flex .ant-typography a {
        color: #000;
    }

.no-underline .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-decoration: unset;
    text-decoration-color: #255398 !important;
    color: #000;
}

.ant-message {
    top: 60px !important;
    z-index: 111111;
}

.ant-message-notice-content {
    padding: 0 !important;
    font-size: 16px;
}

.ant-message-custom-content span {
    top: 3px;
    position: relative;
}

.ant-message-success, .ant-message-loading {
    padding: 5px 10px !important;
    border-radius: 3px;
    background: #e6f7ff;
    border: 1px solid #91d5ff;
}

.ant-message-success {
    padding: 0 10px 0 0 !important;
    background: #d4edda !important;
}

.ant-message-error {
    background: #f8d7da !important;
    padding: 0 10px 0 0 !important;
}

.ant-message .anticon {
    border-radius: 3px;
}

.ant-message-notice-content span.anticon.anticon-check-circle {
    padding: 10px;
    color: #fff !important;
    background: #28a745;
    top: 0 !important;
}

.ant-message-notice-content span.anticon.anticon-close-circle {
    padding: 10px;
    color: #fff !important;
    background: #ff4d4f;
    top: 0 !important;
}

.ant-input-affix-wrapper::before {
    content: unset !important;
}

.ant-input-affix-wrapper.form-control-custom {
    padding: 0 .875rem;
    display: flex;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: silver;
}

.form-control-custom:focus, .form-control:focus {
    color: #000;
    background-color: #fff;
    border-color: #255398;
    outline: 0;
    box-shadow: 0 0 0 0.2rem transparent;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.ant-drawer-close .fa-chevron-left {
    float: left;
}

.ant-select-item-group {
    color: rgb(111, 178, 70);
    font-size: 14px;
    cursor: default;
    font-weight: 600;
}

/*.anticon {
    color: white !important;
}*/

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn-primary {
    float: left;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn {
    margin: 0 8px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
    float: unset;
}

.d2p-reporting-card .ant-card-head {
    background: #255398;
    color: #fff;
    text-align: center;
    border-bottom: 6px solid transparent;
    border-image: linear-gradient(45deg, #36393D, #848B96, #36393D);
    border-image-slice: 1;
}

.d2p-reporting-card {
    border-bottom: 6px solid transparent;
    /*border-image: linear-gradient(45deg, #36393D, #848B96, #36393D);
    border-image-slice: 1;*/
    border-radius: 4px;
    height: 100%;
    border-color: #36393D;
}

.d2p-taskmgr-card .ant-card-head {
    background: #255398;
    color: #fff;
    text-align: center;
    border-bottom: 3px solid transparent;
    border-image: linear-gradient(45deg, #36393D, #848B96, #36393D);
    border-image-slice: 1;
    height: 5px;
    font-size: 11px !important;
}

.d2p-taskmgr-card {
    border-bottom: 3px solid transparent;
    /*border-image: linear-gradient(45deg, #36393D, #848B96, #36393D);
    border-image-slice: 1;*/
    border-radius: 4px;
    height: 100%;
    border-color: #36393D;
    font-size: 11px !important;
}

/*.ant-input-search-button {
    background: #fff !important;
}

.anticon-search {
    color: #255398 !important;
}*/

.ant-tooltip {
    z-index: 111111 !important;
}

.ant-popover {
    z-index: 111111 !important;
}

.ant-table-selection-col, .ant-table-selection-column {
    display: none;
}table with a potential new CRM customer

.ant-upload {
    
    margin-top: 5px;
}

.ant-upload-list.ant-upload-list-picture {
    width: 100%;
}

.custom-upload.row > div {
    flex: 0;
    margin: 5px 10px;
}

.custom-upload.row > div > div {
    display: flex;
}

.table-row-prospect {
    background-color: #d6eaf8;
}

.table-row-exhibitor {
    background-color: #fef9e7;
}

.ant-comment-content-author-time {
    color: #275282 !important;
}

.ant-comment-content-author-name {
    color: #275282 !important;
}