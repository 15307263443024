.background {
    background-image: linear-gradient(to top right, rgba(128, 128, 128, .2) 20%, white 50%, rgba(128, 128, 128, .2) 90%);
    height: 100vh;
}

html body .blankpage-form-field {
    position: fixed;
    top: 45%;
    left: 50%;
    width: auto;
    min-width: 320px;
    height: auto;
    transform: translate(-50%, -50%);
}

.page-logo {
    height: 80px;
    width: 16.875rem;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding: 0 2rem;
}

.blankpage-form-field .page-logo {
    background-image: linear-gradient( 0.25turn, lightgrey 0%, white 50%, lightgrey 100%);
    background-color: rgba(82,81,80, .3);
}

.page-logo, body:not(.header-function-fixed) .page-logo {
    transition: all 470ms cubic-bezier(0.34, 1.25, 0.3, 1);
}

html body .blankpage-form-field .card {
    margin: 0;
    padding: 13.5px 20px 15.5px;
    box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
}
