:root {
    --royal-blue: rgb(24,110,178); /*#0E428D*/
    --bg-color: #fff;
    --nav-text: #000;
    --dark-blue: rgb(24,110,178); /*#3E4C8B*/
}

[data-theme="dark"] {
    --bg-color: #292929;
}
