:root {
    --d2p-blue: #255398;
}

body {
    font-family: roboto,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 1.5rem !important;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.border-bottom-left-radius-0 {
    border-bottom-left-radius: 0 !important;
}

.border-bottom-right-radius-0 {
    border-bottom-right-radius: 0 !important;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 4px;
}

.btn:hover {
    color: #275282 !important;
    text-decoration: none;
    background-color: #fff !important;
}

.btn-info:hover {
    color: #fff !important;
    background-color: #138496 !important;
    border-color: #117a8b !important;
}

.btn-default {
    background-color: #f5f5f5;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#f5f5f5), to(#f1f1f1));
    background-image: linear-gradient(to top, #f5f5f5, #f1f1f1);
    color: #444;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

    .btn-default:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid #c6c6c6;
        color: #333;
        z-index: 2;
    }

    .btn-default:focus {
        border-color: #275282 !important;
        z-index: 3;
    }

.btn-outline-primary {
    border-color: #255398 !important;
    color: #000 !important;
}

    .btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
        color: #000;
        background-color: #275282;
        border-color: #275282;
    }

    .btn-outline-primary:focus, .btn-outline-primary.focus {
        box-shadow: 0 0 0 0.2rem #e6f7ff;
    }

.active.btn-default {
    background: #a38cc6;
    color: #fff;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset !important;
}

.btn-submit {
    color: #fff;
    background-color: #275282;
    border-color: #275282;
}

    .btn-submit:hover {
        color: #fff;
        background-color: #275282;
        border-color: #275282;
    }

    .btn-submit:focus, .btn-submit.focus {
        color: #fff;
        background-color: #275282;
        border-color: #275282;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
    }

    .btn-submit.disabled, .btn-submit:disabled {
        color: #fff;
        background-color: #1dc9b7;
        border-color: #1dc9b7;
    }

    .btn-submit:not(:disabled):not(.disabled):active, .btn-submit:not(:disabled):not(.disabled).active,
    .show > .btn-submit.dropdown-toggle {
        color: #fff;
        background-color: #179c8e;
        border-color: #159184;
    }

        .btn-submit:not(:disabled):not(.disabled):active:focus, .btn-submit:not(:disabled):not(.disabled).active:focus,
        .show > .btn-submit.dropdown-toggle:focus {
            -webkit-box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
        }

.page-content-wrapper {
    background-color: #fff;
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    padding: 0;
    flex-basis: 100%;
    flex-direction: column;
    width: 0;
    min-width: 0;
    max-width: 100%;
    min-height: 1px;
}

.page-inner, .page-wrapper {
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    width: 100%;
}

.page-inner {
    min-height: 100vh;
}

.page-content {
    flex: 1 1 auto;
    order: 3;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 0rem !important;
}

@media screen and (min-width: 992px) {
    .nav-function-top .page-wrapper {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: 992px) {
    .nav-function-top .page-wrapper {
        padding-left: 0;
    }

    .page-content {
        padding: 1.5rem 2rem;
    }
}

@media screen and (max-width: 991px) {
    .page-content {
        padding: 0 1rem;
    }
}

.page-inner, .page-wrapper {
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    width: 100%;
}

.page-wrapper {
    position: relative;
}

.smart-form {
    color: #666;
    margin: 10px 0;
    outline: none;
    position: relative;
    border: .5px solid lightgrey;
    padding-bottom: 10px;
    border-radius: 5px;
}

    .smart-form .form-group {
        padding: 0 10px;
    }

    .smart-form header {
        background-color: #ededed;
        margin: 0;
        padding: 10px !important;
        color: #000;
        font-weight: 600;
        margin-bottom: 10px !important;
        border-bottom: 2px solid #255398;
    }

    .smart-form .row {
        margin-left: .75rem;
        margin-right: .75rem;
    }

.margin-25-10 {
    margin: 25px 10px;
}

.fs-13 {
    font-size: 13px;
}

.fs-15r {
    font-size: 1.5rem !important;
}

.fs-125r, .form-label.fs-125r {
    font-size: 1.25rem !important;
}

.hover:hover {
    cursor: pointer;
}

.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    background: #fff;
    border-radius: 6px;
    margin: 10px;
    padding: 10px;
    transition: box-shadow .5s, transform .5s;
}

.draggable-item.card-container {
    padding: unset !important;
    background: unset !important;
}

.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper:hover {
    cursor: grab;
}

.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
    width: 50%;
}

.a {
    color: var(--d2p-blue);
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
}


.smooth-dnd-container {
    min-height: 125px !important;
    height: 100%;
}

.green-header {
    color: var(--d2p-blue);
    font-style: italic;
    text-transform: uppercase;
}

.green-bold {
    color: var(--d2p-blue);
    font-weight: bold;
}

.fa-grip-horizontal:hover {
    cursor: move;
}

.current-user {
    color: #255398;
    font-weight: bold;
    font-size: 14px;
    padding: 1.50rem 2rem 0.8125rem 2rem !important;
}

.frame-heading {
    color: var(--d2p-blue);
    font-weight: bold;
    font-size: 18px !important;
}

.frame-sub-heading {
    color: black;
    font-weight: bold;
    font-size: 12px;
}

.frame-customer-type {
    color: black;
    font-size: 11px;
}

.highlight {
    background: rgba(37, 83, 152, .3);
}

.ant-select-item-group {
    color: #255398;
    font-weight: bold;
}

.slide-pane__overlay {
    z-index: 11111;
}

.modal {
    z-index: 99999;
}

.slide-pane-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1.00rem;
    height: 90%;
    overflow-y: auto;
    line-height: 2rem;
}

.smallPane {
    width: 25% !important;
}

.ant-select-dropdown {
    z-index: 111111;
}

.am-message-container {
    z-index: 111111 !important;
}

.am-confirm-message {
    z-index: 111111 !important;
}

#am-confirm-container > div {
    z-index: 111111 !important;
}

.mt-n1 {
    margin-top: -1.00rem !important;
}

.color-dark-blue, .text-primary {
    color: var(--dark-blue) !important;
}

.rcorners {
    border-radius: 5px;
    border: 1px solid var(--dark-blue);
    padding: 0px;
}

.refresh-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: #fff;
    top: 0;
    opacity: .7;
}

.scrollableDiv {
    max-height: 100%;
    overflow: auto;
}

.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    color: #255398;
    font-weight: 600;
}

    .strike > span {
        position: relative;
        display: inline-block;
    }

        .strike > span:before,
        .strike > span:after {
            content: "";
            position: absolute;
            top: 50%;
            width: 9999px;
            height: 1px;
            background: #255398;
        }

        .strike > span:before {
            right: 100%;
            margin-right: 15px;
        }

        .strike > span:after {
            left: 100%;
            margin-left: 15px;
        }

.panel-hdr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    min-height: 3rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 4px 4px 0 0;
    -webkit-transition: background-color 0.4s ease-out;
    transition: background-color 0.4s ease-out;
    /* add padding to first and last child */
    /* adjusts title */
}

.panel-hdr.noTitle {
    display: inline-block !important;
    /*    float: right !important*/
}

.customerstatusgreen {
    background-color: green;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    font-weight: bold;
}

.customerstatusred {
    background-color: red;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    font-weight: bold;
}

.customerstatusyellow {
    background-color: yellow;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: black;
    font-weight: bold;
}

.btn-link {
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    padding: 0px !important;
    font-size: 16px !important;
}

/*.btn-link {
    font-weight: 400;
    text-decoration: underline;
    background-color: none !important;
    font-size: 14px;
    padding: 0px !important;
}*/

    .btn-link:hover {
        color: #1890ff;
        text-decoration: none;
    }